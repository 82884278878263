<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px;">
      <div class="query-c">
        <!-- 查询： -->
        <!-- <Input placeholder="请输入用户名称" v-model="name" style="width: auto;margin-right:10px;" /> -->
        <!-- <Input placeholder="请输入用户手机号" v-model="phone" style="width: auto;margin-right:10px;" /> -->
        <!-- <DatePicker v-model="date" format="yyyy-MM-dd hh:mm:ss" @on-change="changeDate" type="daterange"
          placement="bottom-end" placeholder="Select date" style="width: 200px;margin-right:10px;" /> -->
          <Button type="primary" size="small" @click="showInfoDialog">新增</Button>
          <!-- <Button type="primary" size="small" @click="getPlotList">查询</Button> -->
      </div>
      <br>
      <Table max-height="670" border stripe :columns="columns" :data="data">
        <template #image_url="{ row }">
          <div>
            <img :src="`https://${row.image_url}`" :fit="'cover'" width="100px" height="100px"  />
          </div>
        </template>
        <template #price="{ row }">
          <div>{{ row.price }}</div>
        </template>
        <template #createdAt="{ row }">
          <div>{{ timeFormatter(row.createdAt, '{year}-{month}-{day} {hour}:{minute}:{second}') }}</div>
        </template>
        <template #updatedAt="{ row }">
          <div>{{ timeFormatter(row.updatedAt, '{year}-{month}-{day} {hour}:{minute}:{second}') }}</div>
        </template>
        <template #action="{ row, index }">
          <div>
            <Button class="operate-btn" type="primary" size="small"
              @click="showInfoDialog({ row, index })">编辑</Button>
            <Button class="operate-btn" type="primary" size="small"
              @click="showTreeDialog({ row, index })">果树</Button>
            <Button class="operate-btn" type="error" size="small"
              @click="deleteItem({ row, index })">删除</Button>
          </div>
        </template>
      </Table>
      <br>
      <Page :total="total" :page-size="pageSize" show-elevator @on-change="changePage" />
    </div>

    <Modal v-model="plotVisible" title="地块信息"  width="960">
      <Form ref="plotInfo" :model="plotInfo" :label-width="120" :rules="ruleInline">
        <FormItem prop="tree_type_id" label="果树种类">
          <Select v-model="plotInfo.tree_type_id" style="width:140px" @on-change="treeTypeChange">
              <Option v-for="item in treeTypeList" :value="`${item.id}`" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem prop="name" label="地块名称">
          <Input type="text" v-model="plotInfo.name" placeholder="地块名称" maxlength="64"/>
        </FormItem>
        <FormItem prop="description" label="地块描述">
          <!-- <editor :value="plotInfo.description"  toolbarId="description" editorRef="description"
                @change="editorChange($event,'descript')"></editor> -->
          <Input type="textarea" v-model="plotInfo.description" placeholder="地块描述" maxlength="1024"/>
        </FormItem>
        <FormItem prop="fileList" label="展示图">
          <upload @uploadSuccess="uploadSuccess" :limit="3" @uploadRemove="uploadRemove" :fileList="fileList"></upload>
        </FormItem>
        <FormItem prop="details" label="图文详情">
          <editor :value="plotInfo.details" toolbarId="details" editorRef="details"
                @change="editorChange($event,'details')"></editor>
          <!-- <Input type="textarea" v-model="plotInfo.details" placeholder="图文详情" maxlength="1024"/> -->
        </FormItem>
        <FormItem prop="price" label="价格（元）">
          <Input type="text" v-model="plotInfo.price" placeholder="价格（元）" />
        </FormItem>
        <FormItem prop="totalNumbers" label="初始果树数量" v-if="!plotInfo.id">
          <Input type="text" v-model="plotInfo.totalNumbers" placeholder="果树数量" />
        </FormItem>
        <FormItem prop="tags" label="标签">
          <Tag v-for="(item, idx) in tags" :key="`tag-${item}-${idx}`" size="large" :name="item" closable
             @on-close="removeTag(idx)" @click="activeTagInp(idx)">
            <Input :ref="`tag-inp-${idx}`" maxlength="10" v-if="activeTagIdx === idx" type="text" :value="item" placeholder="标签"
              @on-blur="changeTag($event,tags)" size="small" clearable  style="width:80px"/>
            <div v-else @click="activeTagInp(idx)">{{ item }}</div>
          </Tag>
          <Button icon="ios-add" type="dashed" size="small" @click="addTag">添加标签</Button>
        </FormItem>
      </Form>
      <template #footer>
        <Button  size="small"  @click="cancel">取消</Button>
        <Button type="primary" size="small"  @click="updatePlot">确认</Button>
      </template>
    </Modal>

    <Modal v-model="treeVisible" title="果树列表" width="860" footer-hide>
      <div class="query-c">
        <!-- 查询： -->
        添加果树：
          <Input placeholder="请输入添加果树棵树" v-model="addNum" style="width: 60px;margin-right:10px;" />棵
        <!-- <Input placeholder="请输入用户手机号" v-model="phone" style="width: auto;margin-right:10px;" /> -->
        <!-- <DatePicker v-model="date" format="yyyy-MM-dd hh:mm:ss" @on-change="changeDate" type="daterange"
          placement="bottom-end" placeholder="Select date" style="width: 200px;margin-right:10px;" /> -->
          <Button type="primary" size="small" @click="bindNumber">添加</Button>
           <!-- <Button type="primary" size="small" @click="getPlotList">查询</Button> -->
      </div>
      <Table width="800" max-height="400" border stripe :columns="treeColumns" :data="treeList">

        <template #sale_status="{ row, index }">
          <div class="sale" :class="{red:row.sale_status !== 1}">
            {{ row.sale_status === 1 ? '在售' : '已售出'}}
          </div>
        </template>
        <template #action="{ row, index }">
          <div>
            <!-- <Button class="operate-btn" type="primary" size="small"
              @click="showInfoDialog({ row, index })">编辑</Button> -->
            <Button class="operate-btn" type="error" size="small"
              @click="deleteTree({ row, index })">移除</Button>
          </div>
        </template>
      </Table>
      <br>
      <Page :total="totalTree" :page-size="pageSizeTree" show-elevator @on-change="changePageTree"  :model-value="pageTree"/>
    </Modal>
  </div>
</template>

<script>
import { getPlotList, createPlot, updatePlot, deletePlotById, getPlotTreeList, deletePlotTreeById,
    getTreeTypeList, initializeNumbers } from '@/api'
import { formatterPrice, timeFormatter } from '@/utils'
import upload from '@/components/upload'
import editor from '@/components/editor'

export default {
    name: 'plot',
    components: {
        upload,
        editor,
    },
    data() {
        const validatePrice = (rule, value, callback) => {
            console.log(value)
            if (/^(\+|-)?\d+(\.\d{1,2})?$/.test(value)) {
                callback()
            } else {
                return callback(new Error('价格格式有误,最多精确到分（2位小数）！'))
            }
        }
        const validateFile = (rule, value, callback) => {
            console.log(value)
            if (!this.plotInfo.image_url || !this.plotInfo.sub_image_url1 || !this.plotInfo.sub_image_url2) {
                return callback(new Error('请上传3张展示图！'))
            }
            callback()
        }
        const validateDetails = (rule, value, callback) => {
            console.log(this.plotInfo)
            if (!this.plotInfo?.details?.length) {
                return callback(new Error('图文详情不能为空'))
            }
            callback()
        }
        return {
            columns: [
                {
                    title: '序号',
                    width: 80,
                    key: 'index',
                },
                {
                    title: '地块名称',
                    key: 'name',
                },
                {
                    title: '主图',
                    slot: 'image_url',
                    width: 140,
                },
                {
                    title: '价格（元）',
                    slot: 'price',
                },
                {
                    title: '创建时间',
                    slot: 'createdAt',
                    width: 200,
                },
                {
                    title: '更新时间',
                    slot: 'updatedAt',
                    width: 200,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 150,
                    align: 'center',
                },
            ],
            data: [],
            total: 0,
            ruleInline: {
                tree_type_id: [{ required: true, message: '请选择果树类型', trigger: 'change' }],
                name: [{ required: true, message: '不能为空', trigger: 'blur' }],
                description: [{ required: true, message: '描述为空', trigger: 'blur' }],
                price: [{ required: true, validator: validatePrice, trigger: 'blur' }],
                totalNumbers: [{ required: true, message: '不能为空', trigger: 'blur' }],
                fileList: [{ required: true, validator: validateFile, trigger: 'blur' }],
                details: [{ required: true, validator: validateDetails, trigger: 'blur' }],
            },
            plotInfo: {},
            plotVisible: false,
            page: 1,
            pageSize: 10,
            pageTree: 1,
            pageSizeTree: 10,
            totalTree: 0,
            addNum: 0,
            phone: '',
            name: '',
            fileList: [],
            treeTypeList: [],
            treeType: null,
            tags: [],
            activeTagIdx: null,
            date: [new Date(new Date().getTime() - 3600000 * 24 * 30), new Date()],
            treeVisible: false,
            treeList: [],
            treeColumns: [
                {
                    title: '序号',
                    width: 80,
                    key: 'index',
                },
                {
                    title: '果树编号',
                    key: 'number_id',
                },
                {
                    title: '果树类型',
                    key: 'tree_type',
                },
                {
                    title: '售出状态',
                    slot: 'sale_status',
                },
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center',
                },
            ],
        }
    },
    watch: {
        treeVisible(val) {
            this.pageTree = 1
            this.pageSizeTree = 10
            if (val) {
                this.getPlotTreeList()
            }
        },
    },
    mounted() {
        this.getPlotList()
        this.getTreeTypeList()
    },
    methods: {
        timeFormatter(date, fmt) {
            return timeFormatter(date, fmt)
        },
        changeDate(date) {
            this.date = date
        },
        changePage(page) {
            console.log(page)
            this.page = page
            this.getPlotList()
        },
        treeTypeChange(e) {
            console.log(e)
            console.log(this.plotInfo)
        },
        async getTreeTypeList() {
            const { success, data } = await getTreeTypeList({ page: 1, pageSize: 99999 })
            if (success) {
                this.treeTypeList = data.data
            }
        },
        async getPlotList() {
            const param = {
                page: this.page,
                pageSize: this.pageSize,
                // startDate: this.date[0],
                // endDate: this.date[1],
                name: this.name,
                // phone: this.phone,
            }
            const { success, data: { data, total } } = await getPlotList(param)
            if (success) {
                this.total = total
                this.data = data.map((i, idx) => {
                    i.index = (this.page - 1) * this.pageSize + idx + 1
                    i.price = `${formatterPrice(i.price)}`
                    return i
                })
            }
        },
        showInfoDialog({ row, index }) {
            console.log(index)
            this.$refs.plotInfo.resetFields()
            this.fileList = []
            this.plotVisible = true
            this.plotInfo = row ? JSON.parse(JSON.stringify(row)) : {}
            if (this.plotInfo.image_url) {
                this.fileList.push({ status: 'finished', image_url: this.plotInfo.image_url })
            }
            if (this.plotInfo.sub_image_url1) {
                this.fileList.push({ status: 'finished', image_url: this.plotInfo.sub_image_url1 })
            }
            if (this.plotInfo.sub_image_url2) {
                this.fileList.push({ status: 'finished', image_url: this.plotInfo.sub_image_url2 })
            }
            try {
                this.tags = JSON.parse(this.plotInfo.tags || '[]')
            } catch (err) {
                this.tags = []
            }
            console.log(this.plotInfo)
        },
        cancel() {
            this.plotInfo = {}
            this.fileList = []
            this.plotVisible = false
        },
        showTreeDialog({ row, index }) {
            console.log(index)
            this.plotInfo = row ? JSON.parse(JSON.stringify(row)) : {}
            this.treeVisible = true
        },
        deleteItem({ row, index }) {
            console.log(index)
            console.log(row)
            console.log(this.$Modal)
            this.$Modal.confirm({
                title: '确认删除',
                content: '<p>确认删除</p>',
                onOk: async () => {
                    const param = {
                        id: row.id,
                    }
                    const { success, data } = await deletePlotById(param).catch(() => {
                        this.$Message.error('存在关联的树或订单，请处理完后再删除！')
                    })
                    if (success) {
                        console.log(data)
                        this.$Message.success('操作成功')
                        this.getPlotList()
                    } else {
                        this.$Message.error('存在关联的树或订单，请处理完后再删除！')
                    }
                },
                onCancel: () => {
                    this.plotInfo = {}
                },
            })
        },
        updatePlot() {
            console.log(this.plotInfo)
            this.$refs.plotInfo.validate(async (valid) => {
                if (valid) {
                    const param = {
                        name: this.plotInfo.name,
                        description: this.plotInfo.description ?? '',
                        image_url: this.fileList[0]?.image_url ?? '',
                        sub_image_url1: this.fileList[1]?.image_url ?? '',
                        sub_image_url2: this.fileList[2]?.image_url ?? '',
                        details: this.plotInfo.details,
                        price: this.plotInfo.price * 100,
                        tags: this.tags,
                        total_numbers: this.plotInfo.totalNumbers,
                        tree_type_id: +this.plotInfo.tree_type_id,
                    }
                    if (this.plotInfo.id) {
                        param.id = this.plotInfo.id
                    }
                    console.log(param)
                    const { success, data } = this.plotInfo.id ? await updatePlot(param).catch(() => {
                        this.$Message.error('图文详情内容超出最大长度！')
                    }) : await createPlot(param).catch(() => {
                        this.$Message.error('图文详情内容超出最大长度！')
                    })
                    if (success) {
                        console.log(data)
                        this.plotInfo = {}
                        this.fileList = []
                        this.plotVisible = false
                        this.getPlotList()
                    }
                }
            })
        },
        uploadSuccess(list) {
            console.log(list)
            this.$set(this.plotInfo, 'image_url', list[0].image_url ?? '')
            this.$set(this.plotInfo, 'sub_image_url1', list[1]?.image_url ?? '')
            this.$set(this.plotInfo, 'sub_image_url2', list[2]?.image_url ?? '')
        },
        uploadRemove(file) {
            console.log(file)
            this.fileList.splice(this.fileList.indexOf(file), 1)
        },
        activeTagInp(idx) {
            this.activeTagIdx = idx
            this.$nextTick(() => {
                (this.$refs[`tag-inp-${this.activeTagIdx}`])[0].focus()
            })
        },
        changeTag(e, tags) {
            console.log('e:', e)
            console.log('tags:', tags)
            if (e.target.value) {
                this.$set(this.tags, this.activeTagIdx, e.target.value)
                this.activeTagIdx = null
            } else {
                this.removeTag(this.activeTagIdx)
            }
        },
        addTag() {
            if (this.activeTagIdx === null) {
                this.tags.push('')
                this.activeTagIdx = this.tags.length - 1
                this.$nextTick(() => {
                    (this.$refs[`tag-inp-${this.activeTagIdx}`])[0].focus()
                })
            }
        },
        removeTag(idx) {
            this.tags.splice(idx, 1)
            this.activeTagIdx = null
        },
        changePageTree(page) {
            console.log(page)
            this.pageTree = page
            this.getPlotTreeList()
        },
        async getPlotTreeList() {
            const param = {
                page: this.pageTree,
                pageSize: this.pageSizeTree,
                // startDate: this.date[0],
                // endDate: this.date[1],
                orchardPlotId: this.plotInfo.id,
                // phone: this.phone,
            }
            const { success, data: { data, total } } = await getPlotTreeList(param)
            if (success) {
                this.totalTree = total
                this.treeList = data.map((i, idx) => {
                    i.index = (this.pageTree - 1) * this.pageSizeTree + idx + 1
                    return i
                })
            }
        },
        async bindNumber() {
            console.log(this.addNum)
            const param = {
                orchard_plot_id: this.plotInfo.id,
                total_numbers: +this.totalTree + +this.addNum,
            }
            const { success } = await initializeNumbers(param)
            this.addNum = 0
            if (success) {
                this.pageTree = 1
                this.getPlotTreeList()
            }
        },
        deleteTree({ row }) {
            this.$Modal.confirm({
                title: '确认移除',
                content: '<p>确认移除</p>',
                onOk: async () => {
                    const param = {
                        id: row.id,
                    }
                    const { success, data } = await deletePlotTreeById(param)
                    if (success) {
                        console.log(data)
                        this.pageTree = 1
                        this.getPlotTreeList()
                    }
                    this.$Message.success('操作成功')
                },
                onCancel: () => {
                },
            })
        },
        editorChange({ html }, prop) {
            this.plotInfo[prop] = html
        },
    },
}
</script>

<style scoped>
.operate-btn {
  margin-bottom: 10px;

}


.operate-btn+.operate-btn {
  margin-left: 5px;
}
:deep(.ivu-tag-text){
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.sale{
  color:green;
}
.red{
  color:brown;
}
</style>
